.create-edit-alert-container {
    .title {
        color: var(--independence-grey-dark-n-800-main-text, #242433);
        font-family: Inter;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 34px; 

        margin-top: 30px;
        margin-left: 32px;
    }

    .section {
        display: flex;
        flex-direction: column;
        margin-top: 39px;
        margin-left: 32px;

        .sub-title {
            color: var(--independence-grey-dark-n-800-main-text, #242433);
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
        }

        .form-fields {
            display: flex;
            margin-top: 20px;

            .form-field {
                display: flex;
                flex-direction: column;

                .error {
                    background: #fbe5f1;
                    border-color: #e41e54;
                }

                .email-list-container {
                    display: flex;
                    align-items: center;
                    gap: 6px;
                    margin-top: 10px;
                    flex-wrap: wrap;
                }

                &.category {
                    width: 284px;
                }

                &.statuss {
                    margin-left: 20px;
                    width: 284px;
                }

                &.percentage {
                    width: 284px;
                    font-size: 16px !important;
                }

                &.volume {
                    margin-left: 20px;
                    width: 284px;
                    font-size: 16px !important;
                }

                &.vessel {
                    width: 611px;
                }

                &.email {
                    width: 611px;
                }

                &.add-email-btn {
                    border-radius: 8px;
                    margin-top: 27px;
                    text-transform: capitalize;
                }

                &.remarks {
                    width: 611px;
                }

                &.actions {
                    p {
                        color: var(--shades-independence-grey-dark-n-800-main-text, #242433);
                        font-family: Inter;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px; 

                        margin-top: 10px;
                        margin-bottom: 10px;
                    }
                }

                &.btn-list {
                    display: flex;
                    flex-direction: row;
                    margin-top: 10px;
                    margin-left: auto;
                    margin-right: 30px;
                }

                .label {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 22px;
                    color: #5c5c7a;
                    min-width: 70px;
                }

                .ui.label {
                    font-size: 16px !important;
                }

                .percentage-div {
                    display: flex;
                    gap: 5px;
                    align-items: center;

                    .ui.toggle.checkbox input[type=checkbox]:checked~label::before,
                    .ui.toggle.checkbox input[type=checkbox]:checked:focus~label::before {
                        background: rgb(9, 9, 172) !important;
                    }
                }

                .volume-div {
                    display: flex;
                    gap: 5px;
                    align-items: center;

                    .ui.toggle.checkbox input[type=checkbox]:checked~label::before,
                    .ui.toggle.checkbox input[type=checkbox]:checked:focus~label::before {
                        background: rgb(9, 9, 172) !important;
                    }
                }

                .btn-submit {
                    margin-left: 16px;
                    width: 149px;
                    border-radius: 8px !important;
                    text-transform: capitalize;
                    &.button-bg {
                        background-color: #0000a1;
                        color: #fff !important;
                    }
                }

                .btn-cancel {
                    margin-left: 16px;
                    width: 149px;
                    border-radius: 8px !important;
                    text-transform: capitalize;
                }
            }
        }

        &.last {
            margin-bottom: 70px;
        }
    }
}

.email-item-container {
    display: flex;
    padding: 4px 10px 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 10px;
    background: var(--Independence-Grey-Light-N20, #F0F0F4);

    .email {
        color: var(--independence-grey-dark-n-800-main-text, #242433);
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; 
    }
}

.form-dropdown {
    width: 100% !important;
    min-height: 46px !important;
    border-radius: 8px !important;
    border-color: #d9d9e3 !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    margin-top: 4px;
    font-size: 16px !important;
}

.form-dropdown-multiple {
    width: 100% !important;
    min-height: 46px !important;
    border-radius: 8px !important;
    border-color: #d9d9e3 !important;
    margin-top: 4px;
    font-size: 16px !important;
}